<template>
  <div class="row">
    <div class="col-12">
      <c-table
        ref="table"
        title="시나리오 목록"
        :columns="grid.columns"
        :data="assessStatus.statusList"
        :merge="grid.merge"
        :gridHeight="setheight"
      >
        <template slot="table-button">
          <q-btn-group outline class="float-right">
            <c-btn
              label="위험성평가 결과표 출력"
              icon="print"
              @btnClicked="riskPrint" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='src'">
            <q-btn 
              round unelevated 
              size="6px"
              color="amber" 
              icon="search"
              @click="srcClick(props.row, props.pageIndex)" />
          </template>
          <template v-if="col.name==='customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              color="orange-6"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <template v-else>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-status-info',
  props: {
    assessStatus: {
      type: Object,
      default: function() {
        return {
          statusList: [],
        }
      },
    },
    param: {
      type: Object,
      default: function() {
        return {
          riskAssessmentPlanId: '',
          stepCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'groupMdmSopId' },
        ],
        columns: [],
        data: [],
        height: '500px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      editable: true,
      imprData: null,
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 80) + 'px' : '500px'
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.printUrl = selectConfig.saas.status.print.url;
      // code setting
      this.grid.columns = [
        {
          name: 'processName',
          field: 'processName',
          label: '공종',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          name: 'sopName',
          field: 'sopName',
          label: '작업',
          align: 'center',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'assessVendorName',
          field: 'assessVendorName',
          label: '업체',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          name: 'src',
          field: 'src',
          label: '위치정보',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          type: 'custom',
        },
        {
          name: 'riskHazardClassCd',
          field: 'riskHazardClassCd',
          label: '유해위험요인분류',
          align: 'center',
          style: 'width:180px',
          sortable: false,
        },
        {
          name: 'damageTypeName',
          field: 'damageTypeName',
          label: '위험결과',
          align: 'center',
          style: 'width:90px',
          sortable: false,
        },
        {
          name: 'riskOccurrenceCause',
          field: 'riskOccurrenceCause',
          label: '부적합원인',
          align: 'left',
          style: 'width:300px',
          sortable: false,
        },
        {
          name: 'before',
          field: 'before',
          label: '개선전',
          align: 'center',
          child: [
            {
              name: 'beforeFrequency',
              field: 'beforeFrequency',
              label: '빈도',
              align: 'center',
              style: 'width:50px',
              sortable: false
            },
            {
              name: 'beforeStrength',
              field: 'beforeStrength',
              label: '강도',
              align: 'center',
              style: 'width:50px',
              sortable: false
            },
            {
              name: 'beforeRisk',
              field: 'beforeRisk',
              label: '위험도',
              align: 'center',
              style: 'width:70px',
              sortable: false,
            },
          ]
        },
        {
          name: 'currentSafetyMeasures',
          field: 'currentSafetyMeasures',
          label: '현재안전조치',
          align: 'left',
          style: 'width:300px',
          sortable: false,
          required: true,
        },
        {
          name: 'after',
          field: 'after',
          label: '개선후',
          align: 'center',
          child: [
            {
              name: 'afterFrequency',
              field: 'afterFrequency',
              label: '빈도',
              align: 'center',
              style: 'width:50px',
              sortable: false
            },
            {
              name: 'afterStrength',
              field: 'afterStrength',
              label: '강도',
              align: 'center',
              style: 'width:50px',
              sortable: false
            },
            {
              name: 'afterRisk',
              field: 'afterRisk',
              label: '위험도',
              align: 'center',
              style: 'width:70px',
              sortable: false,
            },
          ]
        },
        {
          name: 'assessDate',
          field: 'assessDate',
          label: '평가일',
          align: 'center',
          style: 'width:130px',
          sortable: false,
        },
        {
          name: 'assessUserName',
          field: 'assessUserName',
          label: '평가자',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          name: 'assessOpinion',
          field: 'assessOpinion',
          label: '평가의견',
          align: 'left',
          style: 'width:300px',
          sortable: false,
        },
        {
          name: 'impr',
          field: 'impr',
          label: '개선진행',
          align: 'center',
          child: [
            {
              name: 'customCol',
              field: 'customCol',
              label: '개선번호/개선진행상태',
              align: 'center',
              style: 'width:150px',
              type: 'custom',
              sortable: false
            },
          ]
        },
      ]
      this.colorItems = [];
      // list setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.$emit('research');
      }
    },
    srcClick(row) {
      this.row = row;
      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.riskAssessmentTargetId,
        maps: row.maps ? row.maps : [],
        deleteMaps: row.deleteMaps ? row.deleteMaps : [],
        sopMapId: this.assessStatus.sopMapId,
        stepCd: this.param.stepCd,
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.type === 'APPLY') {
        this.row.maps = data.maps;
        this.row.deleteMaps = data.deleteMaps;
        
        if (this.row.editFlag !== 'C') {
          this.row.editFlag = 'U'
          this.row.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    riskPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        riskAssessmentPlanId: this.param.riskAssessmentPlanId,
      }
      this.$http.request(
        _result => {
          let fileOrgNm = '위험성평가결과_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );

    },
  }
};
</script>